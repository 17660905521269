/*
Documentation

compile and export all reducers for this system

*/

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';


import auth                     from './reducers/auth/auth';
import system                     from './reducers/system/system';
import media                     from './reducers/media/media';

//tell redux to match the following properties to each reducer
const rootReducer = combineReducers({
    auth,
    system,
    media
})

const store = createStore(rootReducer, applyMiddleware(thunk));;
export default store
