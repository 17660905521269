/*
Documentation

this page holds all of our layouts and functions
that need to fire before the app runs

*/

import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import SystemAlertBS from 'components/markup/system/AlertBS'
import SystemAlert from 'components/markup/system/Alert'
import SystemLoader from 'components/markup/system/Loader'
import StandardLoader from 'components/markup/system/StandardLoader'

import { Helmet } from 'react-helmet'

const Site        = React.lazy(() => import('views/site/layout'));
const HelpLine    = React.lazy(() => import('views/help-line/layout'));
const Auth        = React.lazy(() => import('views/auth/layout'));
const Admin       = React.lazy(() => import('views/admin/layout'));
const Errors      = React.lazy(() => import('views/errors/layout'));

const NoMatchPage = () => {
    return window.location.href = "/notfound"
}; 

class App extends Component {

    state = {
        loaded: false,
        error: false,
        helmet: null
    };

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        if(this.state.shouldRedirect)   { return window.location.href = this.state.shouldRedirect }

        return (

            <>

                <Helmet>
                    <title>Fairmax Law - Law firm practicing Debt Relief, Credit Reporting Issues, Bankruptcy, in Michigan, Ohio, Illinois, Indiana, Wisconsin, Chicago</title>
                    <meta name="Description" content="Fairmax Law is a debt relief bankruptcy lawfirm based out of Dearborn Michigan." />
                </Helmet>

                <SystemAlertBS />
                <SystemAlert />
                <SystemLoader />
                <StandardLoader />

                <BrowserRouter>
                    <Switch>

                        <Route path="/errors" render={props =>
                            <Suspense fallback={<div></div>}>
                                <Errors {...props} />
                            </Suspense>
                         } />

                        <Route path="/auth" render={props =>
                            <Suspense fallback={<div></div>}>
                                <Auth {...props} />
                            </Suspense>
                        } />

                        <Route path="/admin" render={props =>
                            <Suspense fallback={<div></div>}>
                                <Admin {...props} />
                            </Suspense>
                         } />
                         
                        <Route path="/help-line" render={props =>
                            <Suspense fallback={<div></div>}>
                                <HelpLine {...props} />
                            </Suspense>
                         } />

                        <Route path="/notfound" component={NoMatchPage} />  

                        <Route path="/" render={props =>
                            <Suspense fallback={<div></div>}>
                                <Site {...props} />
                            </Suspense>
                       } />

                    </Switch>
                </BrowserRouter>

            </>

        )

    }

}

export default App
