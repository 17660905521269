/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    library_images:     [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_MEDIA_LIBRARY_IMAGES:

            return {
                ...state,
                library_images: action.payload.objects
            }

        default:


            return state;
    }
}



export default reducer;
